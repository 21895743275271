import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
export default () => (
    <Layout>
        <div className="md:flex" style={{ minHeight: '85vh' }}>
            <SEO title="Purchase Cancelled" />
            <div className="w-full bg-white flex items-center justify-center ">
                Purchase was cancelled
            </div>
        </div>
    </Layout>
)
